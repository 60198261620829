// preview css
.home-dark.feature-section {
  .feature-box-01 {
    background: #1c1d24;
  }
}
.preview-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  @media screen and (max-width: 767px) {
    height: calc(100vh - 56px) !important;
    @supports (-webkit-appearance: none) {
      .os-android & {
        height: calc(100vh - 56px) !important;
        min-height: calc(100vh - 56px) !important;
      }
    }
  }
  @media screen and (max-width: 360px) {
    height: 100vh !important;
  }
}
.demo-section {
  @media screen and (min-width: 1920px) {
    .container {
      max-width: 1440px;
      padding-bottom: 0;
    }
  }
  .title {
    margin-top: 25px;
    h3 {
      margin: 0;
      color: #000;
      position: relative;
      z-index: 1;
      font-weight: 800;
      font-size: 20px;
      display: inline-block;
      vertical-align: top;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }

      &::after {
        content: "";
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: rgba(255, 147, 1, 0.5);
        position: absolute;
        top: -8px;
        left: -15px;
        z-index: -1;
      }
    }
  }
  .single-demo {
    transition: 0.3s;
    margin-top: 40px;
    a {
      cursor: pointer !important;
    }

    &:hover {
      transform: translateY(-15px);
    }
  }
  .portfolio-filter-01 {
    padding-top: 0;
  }

  .slick-dots {
    bottom: -40px;
  }
}

.feature-section {
  .container-fluid {
    padding-left: 150px;
    padding-right: 150px;
    @media screen and (max-width: 1499px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .feature-box-01 {
    height: 100%;
    transition: 0.5s;
    &::after {
      display: none;
    }
    h5 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    .icon {
      img {
        width: 55px;
        height: 55px;
        margin-bottom: 30px;
        background: hsla(0, 0%, 100%, 0.1);
        padding: 10px;
        border-radius: 50%;
        transition: 0.5s;
      }
    }
    &:hover {
      background: #222;
      .icon img {
        background: #fff;
      }
    }
  }
}
.title-wrapper {
  .title {
    font-size: 40px;
    font-weight: 800;
    color: #fff;
    margin-top: 0;
    margin-bottom: 40px;
    @media screen and (max-width: 1499px) {
      font-size: 30px;
    }
    .theme-color {
      color: $px-theme;
    }
  }
  .tag {
    background: #dc965a;
    color: #fff;
  }
}
.tag {
  display: inline-block;
  background: #1c1d24;
  padding: 0 10px;
  color: $px-theme;
  border-radius: 4px;
  margin-bottom: 10px;
}
.purchase-block-inner {
  h3 {
    max-width: 600px;
    font-weight: 800;
    margin: 0 auto;
    font-size: 35px;
    display: block;
    padding: 10px 0 30px;
    letter-spacing: 1px;
    line-height: 45px;
    color: #000;
    @media screen and (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .theme-color {
    color: $px-theme;
  }
  .dark-color {
    font-size: 17px;
    font-weight: 500;
    display: inline-block;
  }
  @media screen and (min-width: 1200px) {
    .px-btn {
      font-size: 18px;
      line-height: 55px;
      letter-spacing: 1px;
      padding: 0 35px;
    }
  }
}
.preview-inner {
  .filter {
    padding-left: 0;
    flex-wrap: wrap;
    background: #fff5ec;
    max-width: 408px;
    margin: auto;
    border-radius: 10px;
    padding: 10px 40px;
    margin-bottom: 23px;
  }
}
.preview-header {
  .px-btn {
    line-height: 52px;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      line-height: 42px;
      margin-bottom: 5px;
    }
  }
  h3 {
    font-size: 35px;
    margin: 30px 0 15px;
    color: #fff;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 20px;
      margin-top: 20px;
      line-height: 1.3;
    }
  }
  .theme-color {
    font-size: 40px;
    font-weight: 800;
    line-height: 1;
  }
  .lead {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 25px;
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-brand {
    @media screen and (max-width: 767px) {
      height: 32px;
    }
  }
}

.purchase-section,
section.testimonial,
.footer {
  overflow: hidden;
}

// demo light to dark switch
.demo-toggler {
  width: 25px;
  position: fixed;
  z-index: 99;
  right: 0px;
  top: calc(50% - 65px);
  .toggler-btn {
    border-radius: 4px 0 0 4px;
    background: $px-theme;
    border: 2px solid $px-theme;
    color: #fff;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    position: relative;
    z-index: 2;
    font-size: 14px;
    width: 100%;
    min-width: 100%;
    padding: 10px 0px 8px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      background: #fff5ec;
      a {
        color: #000;
      }
    }
    a {
      color: #fff;
    }
  }
  .inner-text {
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.inner-pages-wrapper {
  .single-demo {
    display: block;
    background: #333;
    padding: 5px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    transition: 0.4s;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: 0.5s;
      @media screen and (max-width: 767px) {
        background: rgba(0, 0, 0, 0);
      }
    }
    img {
      width: 100%;
    }
    &:hover {
      transform: translateY(-10px);
      &::before {
        background: rgba(0, 0, 0, 0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .inner-title {
    color: #fff;
    text-align: center;
    position: relative;
    background: #1c1d24;
    padding: 10px;
    margin: 0;
    font-weight: 600;
  }
}

.section.inner-pages-wrapper {
  background: #000;
}

.preview-sticker {
  width: 134px;
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1d24;
  color: #fff;
  font-weight: 400 !important;
  font-size: 22px;
  flex-direction: column;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 99;
  line-height: 1.1;
  @media screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    left: 15px;
    font-size: 20px;
  }
  .price {
    font-weight: 800;
    font-size: 35px;
    margin-top: 5px;
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
  &.price-sticker {
    background: #ca1d1d;
    color: #fff;
    top: auto;
    left: auto;
    right: 20px;
    bottom: 20px;
    @media screen and (max-width: 767px) {
      right: 15px;
      bottom: 15px;
    }
  }
  &.react-sticker {
    border-radius: 0px 0px 80px 80px;
    @media screen and (max-width: 767px) {
      img {
        max-width: 55%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .preview-section {
    padding: 0 5px;
  }
  p {
    br {
      display: none;
    }
  }
}
